import { RefObject, useCallback, useEffect } from "react";

const useScrollFade = (delay: number, dom: RefObject<any>) => {
    const handleScroll = useCallback(
        ([entry]: IntersectionObserverEntry[]) => {
            const { current } = dom;
            if (entry.isIntersecting && current) {
                current.style.transitionProperty = "opacity ,transform";
                current.style.transitionDuration = "0.7s";
                current.style.transitionTimingFunction = "ease-out";
                current.style.transitionDelay = `${delay}s`;
                current.style.opacity = "1";
                current.style.transform = "translate3d(0, 0, 0)";
            }
        },
        [dom, delay]
    );

    useEffect(() => {
        if (dom) {
            let observer: IntersectionObserver;
            const { current } = dom;
            if (current) {
                current.style.opacity = "0";
                current.style.transform = "translate3d(0, 30%, 0)";
                observer = new IntersectionObserver(handleScroll, {
                    threshold: 0.2,
                });
                observer.observe(current);
                return () => observer && observer.disconnect();
            }
        }
        return;
    }, [handleScroll, dom]);

    return {
        title: "return",
    };
};

export default useScrollFade;

import "./css/index.css";
import { UserAgentType } from "../../../common/common.inerface";
import { useRef } from "react";
import useScrollFade from "../../../hook/UseFade";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../reducer";
import { LangType } from "../../../reducer/config";
type Props = {
  userAgent: UserAgentType;
};

const Section4 = ({ userAgent }: Props) => {
  const lang = useSelector<RootState, LangType>((state) => state.config.lang);
  const scrollRef = useRef(null);
  const scrollRef2 = useRef(null);
  useScrollFade(0, scrollRef);
  useScrollFade(0.2, scrollRef2);

  return (
    <section className="section4">
      <div ref={scrollRef} className="img-wrapper">
        <img
          src={`/assets/section4/${userAgent}/handsmotion.png`}
          srcSet={`
            /assets/section4/${userAgent}/handsmotion@2x.png 2x,
            /assets/section4/${userAgent}/handsmotion@3x.png 3x,
          `}
          alt=""
        />
      </div>
      <div className="section4-title-wrapper" ref={scrollRef2}>
        <b className="title font">{`Hands in Motion, 
Explore New Possibilities`}</b>
        <b className="subtitle">
          {lang === "KOR"
            ? "당신의 공간에 혁신을 더해보세요"
            : "Enhance your space with innovation."}
        </b>
        <button className="application-btn">
          <Link to={"/contact"}>
            {lang === "KOR" ? "도입 상담 신청하기" : "Request a Consultation"}
          </Link>
        </button>
      </div>
    </section>
  );
};

export default Section4;

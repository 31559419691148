import React, { useRef } from "react";
import "./css/index.css";
import { UserAgentType } from "../../../common/common.inerface";
import useScrollFade from "../../../hook/UseFade";
import { useSelector } from "react-redux";
import { RootState } from "../../../reducer";
import { LangType } from "../../../reducer/config";
type Props = {
  userAgent: UserAgentType;
};

const Section6 = ({ userAgent }: Props) => {
  const scrollRef = useRef(null);
  const scrollRef2 = useRef(null);
  const scrollRef3 = useRef(null);
  const lang = useSelector<RootState, LangType>((state) => state.config.lang);
  useScrollFade(0, scrollRef);
  useScrollFade(0.2, scrollRef2);
  useScrollFade(0.3, scrollRef3);

  const historyArr = [
    {
      year: 2024,
      text: [
        "2024.02 삼성 C-Lab Outside 6기 선정",
        "2024.07 여성창업경진대회 신한은행그룹장상 수상",
      ],
      eng: [
        "2024.02 Selected for Samsung C-Lab Outside 6th Cohort",
        "2024.07 Awarded Shinhan Bank Group President’s Prize at the Women’s Startup Competition",
      ],
    },
    {
      year: 2023,
      text: [
        `${
          userAgent === "mb"
            ? `2023.02 스타트업 프런티어 
데모데이 대상 수상`
            : "2023.02 스타트업 프런티어 데모데이 대상 수상"
        }`,
        "2023.03 신한스퀘어브릿지 대구 2기 선정",
        "2023.04 비대면 스타트업 육성사업 선정",
        "2023.04 산학연 Collabo R&D 사업 선정",
        `${
          userAgent === "mb"
            ? `2023.05 대구 글로벌 로봇 벤처 스타트업 
육성지원사업 선정`
            : "2023.05 대구 글로벌 로봇 벤처 스타트업 육성지원사업 선정"
        }`,
        "2023.06 TIPS 선정",
        "2023.07 후속 Seed 투자 유치",
        `${
          userAgent === "mb"
            ? `2023.09 대구청년창업아이디어경진대회 대상 
(중소벤처기업부장관상) 수상`
            : "2023.09 대구청년창업아이디어경진대회 대상 (중소벤처기업부장관상) 수상"
        }`,
      ],
      eng: [
        "2023.02 Grand Prize at Startup Frontier Demo Day",
        "2023.03 Selected for Shinhan Square Bridge Daegu 2nd Cohort",
        "2023.04 Selected for the Non-face-to-face Startup Support Program",
        "2023.04 Selected for the Industry-University-Institute Collaboration R&D Project",
        "2023.05 Selected for the Daegu Global Robot Venture Startup Support Program",
        "2023.06 Selected for TIPS (Tech Incubator Program for Startups)",
        "2023.07 Attracted Follow-up Seed Investment",
        "2023.09 Grand Prize at Daegu Youth Startup Idea Competition (Minister of SMEs and Startups Award)",
      ],
    },
    {
      year: 2022,
      text: [
        "2022.03 청년창업사관학교 대구 12기 선정",
        "2022.04 법인 설립",
        "2022.07 Seed 투자 유치",
        "2022.12 K-Camp 대구 데모데이 대상 수상",
      ],
      eng: [
        "2022.03 Selected for Daegu Youth Startup Academy 12th Cohort",
        "2022.04 Quester Inc. Established",
        "2022.07 Attracted Seed Investment",
        "2022.12 Grand Prize at K-Camp Daegu Demo Day",
      ],
    },
  ];
  const infoArr = [
    {
      title: "address",
      subtitle:
        lang === "KOR"
          ? userAgent !== "mb"
            ? "대구광역시 달성군 현풍읍 테크노중앙대로 333, R7 (산학협력관)"
            : `대구광역시 달성군 현풍읍 
테크노중앙대로 333, R7 (산학협력관)`
          : `R7, 333, Techno jungang-daero, Hyeonpung-eup,
Dalseong-gun, Daegu, Republic of Korea`,
    },
    {
      title: "phone",
      subtitle: "070 - 4458 - 5995",
    },
    {
      title: "e-mail",
      subtitle: "quester@quester.kr",
    },
  ];
  return (
    <section className="section6">
      <div className="wrapper">
        <div className="sub-img-wrapper" ref={scrollRef}>
          <img
            className="sub-img"
            src="/assets/section6/pc/subbackground.png"
            srcSet="
          /assets/section6/pc/subbackground@2x.png 2x,
          /assets/section6/pc/subbackground@3x.png 3x
        "
            alt=""
          />
        </div>
        <div className="section6-text-wrapper" ref={scrollRef2}>
          <p className="title font">history</p>
          {lang === "ENG" && (
            <div className="subtitle-wrapper">
              <p className="subtitle">Quester has consistently demonstrated</p>
              <p className="subtitle-bold">
                its potential for success by {userAgent === "mb" && <br />}{" "}
                embracing ongoing challenges
              </p>
            </div>
          )}
          {lang === "KOR" && (
            <div className="subtitle-wrapper">
              <p className="subtitle">퀘스터는 끊임없는 도전으로</p>
              <p className="subtitle-bold">
                성공 가능성을 입증
                <span className="subtitle">해오고 있습니다</span>
              </p>
            </div>
          )}
        </div>
        {userAgent === "pc" && <div className="line" />}
        <div className="history-wrapper" ref={scrollRef3}>
          {historyArr.map(({ year, text, eng }, idx) => (
            <div className="history-box" key={idx}>
              <div className="circle">{year}</div>
              {lang === "KOR" && (
                <ul className="history-lists">
                  {text.map((item, idx) => (
                    <li key={idx} className="history-list">
                      {item}
                    </li>
                  ))}
                </ul>
              )}
              {lang === "ENG" && (
                <ul className="history-lists">
                  {eng.map((item, idx) => (
                    <li key={idx} className="history-list">
                      {item}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ))}
          <div className="line" />
        </div>
      </div>
      <div
        className="contactus-wrapper"
        style={{
          backgroundImage: `
        url(/assets/section6/${userAgent}/contactus.png)`,
        }}
      >
        <h2 className="contactus-title font">contact us</h2>
        <div className="info-wrapper">
          {infoArr.map(({ title, subtitle }, idx) => (
            <div className="info-box" key={idx}>
              <span className="info-title font">{title}</span>
              <span className="info-subtitle font">{subtitle}</span>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Section6;

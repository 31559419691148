import React, { useRef } from "react";
import "./css/index.css";
import { UserAgentType } from "../../../common/common.inerface";
import useScrollFade from "../../../hook/UseFade";
import { RootState } from "../../../reducer";
import { useSelector } from "react-redux";
import { LangType } from "../../../reducer/config";
type Props = {
  userAgent: UserAgentType;
};

const Section2 = ({ userAgent }: Props) => {
  const lang = useSelector<RootState, LangType>((state) => state.config.lang);
  const scrollRef1 = useRef(null);
  const scrollRef2 = useRef(null);
  const scrollRef3 = useRef(null);
  const scrollRef4 = useRef(null);
  const scrollRef5 = useRef(null);
  useScrollFade(0, scrollRef1);
  useScrollFade(0.2, scrollRef2);
  useScrollFade(0.3, scrollRef3);
  useScrollFade(0.4, scrollRef4);
  useScrollFade(0.4, scrollRef5);
  return (
    <section className="section2">
      <div className="gradient-line" ref={scrollRef1} />
      <div className="section2-text-wrapper">
        <b className="section2-text-color font" ref={scrollRef2}>
          OUR MISSION
        </b>
        {lang === "KOR" && (
          <div className="section2-text-title" ref={scrollRef3}>
            퀘스터는 <br /> <b>핸드 트래킹 기술에 집중합니다</b>
          </div>
        )}
        {lang === "ENG" && (
          <div className="section2-text-title" ref={scrollRef3}>
            Quester <br /> <b>focuses on hand tracking technology</b>
          </div>
        )}
        {lang === "KOR" && (
          <div className="section2-text-sub" ref={scrollRef4}>
            {userAgent !== "mb" ? (
              <>
                {" "}
                우리는 다중 센서 융합 기술 기반 고성능 핸드 트래킹 글러브를
                개발하며,
                <br />
                이를 확장현실 기반의 거대한 응용 콘텐츠 시장 및 로보틱스
                시장으로 <br />
                연결하는 것에 도전하고 있습니다.
              </>
            ) : (
              `우리는 다중 센서 융합 기술 기반
고성능 핸드 트래킹 글러브를 개발하며,
이를 확장현실 기반의 거대한 응용 콘텐츠 시장 및
로보틱스 시장으로 연결하는 것에 도전하고 있습니다.`
            )}
          </div>
        )}
        {lang === "ENG" &&
          (userAgent !== "mb" ? (
            <div className="section2-text-sub" ref={scrollRef4}>
              We develop high-performance hand tracking gloves based on <br />
              multi-sensor fusion technology, aiming to connect them with <br />{" "}
              the vast application markets of extended reality and robotics.
            </div>
          ) : (
            <div className="section2-text-sub" ref={scrollRef4}>
              We develop high-performance hand tracking <br /> gloves based on
              multi-sensor fusion technology, <br /> aiming to connect them with
              the vast application <br /> markets of extended reality and
              robotics.
            </div>
          ))}
      </div>
      <img
        ref={scrollRef5}
        className="section2-img"
        src={`/assets/lading/${userAgent}/section2.png`}
        srcSet={`/assets/lading/${userAgent}/section2@2x.png 2x, /assets/lading/${userAgent}/section2@3x.png 3x`}
        alt=""
      />
    </section>
  );
};

export default Section2;

import React, { useRef } from "react";
import "./css/index.css";
import { UserAgentType } from "../../../common/common.inerface";
import useScrollFade from "../../../hook/UseFade";
import { useSelector } from "react-redux";
import { RootState } from "../../../reducer";
import { LangType } from "../../../reducer/config";
type Props = {
  userAgent: UserAgentType;
};

const Section1 = ({ userAgent }: Props) => {
  const lang = useSelector<RootState, LangType>((state) => state.config.lang);
  const scrollRef = useRef(null);
  useScrollFade(0, scrollRef);
  console.log(window.safari);
  return (
    <section className="section1">
      <video autoPlay loop muted playsInline className="section1-img">
        {window.safari ? (
          <>
            <source src="/assets/video/main.mov" type="video/mp4" />
            <source src="/assets/video/main.webm" type="video/webm" />
          </>
        ) : (
          <>
            <source src="/assets/video/main.webm" type="video/webm" />
            <source src="/assets/video/main.mov" type="video/mp4" />
          </>
        )}
      </video>
      <div className="section1-title-wrapper">
        <div className="section1-title font">
          UNLOCK <br /> THE POWER OF MOTION
        </div>
        {lang === "KOR" && (
          <div className="section1-sub">
            정밀한 손동작 추적으로 <br /> 무한한 가능성을 열어갑니다
          </div>
        )}
        {lang === "ENG" && (
          <div className="section1-sub">
            Explore infinite possibilities with <br /> precise hand motion
            tracking
          </div>
        )}
      </div>
    </section>
  );
};

export default Section1;
{
  /* <img
        className="section1-img"
        src={`/assets/lading/${userAgent}/section1.png`}
        srcSet={`/assets/lading/${userAgent}/section1@2x.png 2x ,/assets/lading/${userAgent}/section1@3x.png 3x`}
        alt=""
      /> */
}
